import { Flex, Space } from "antd";
import React from "react";

import styled from "styled-components";
import { Labels } from "../labels/Labels";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { isCurrentTimeInSchedule } from "../../../utils";
import { ucfirst } from "../../../utils";

const ListMeal = styled.div<{ $disabled: boolean }>`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
  background: ${({ $disabled }) => ($disabled ? "#f5f5f5" : "white")};
  box-shadow: ${({ $disabled }) =>
    $disabled ? "1px 2px 0px 0px #edebfb" : "1px 2px 0px 0px #edebfb"};
  padding: 12px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
`;

const GridMeal = styled.div<{ $disabled: boolean }>`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
  background: ${({ $disabled }) => ($disabled ? "#f5f5f5" : "white")};
  box-shadow: ${({ $disabled }) =>
    $disabled ? "1px 2px 0px 0px #edebfb" : "1px 2px 0px 0px #edebfb"};
  padding: 12px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  width: calc(50% - 4px);

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Meal = ({
  viewType,
  children,
  onClick,
  disabled,
}: {
  viewType: "LIST" | "GRID";
  children: React.ReactNode;
  onClick: () => void;
  disabled: boolean;
}) => {
  switch (viewType) {
    case "LIST":
      return (
        <ListMeal $disabled={disabled} onClick={onClick}>
          {children}
        </ListMeal>
      );
    case "GRID":
      return (
        <GridMeal $disabled={disabled} onClick={onClick}>
          {children}
        </GridMeal>
      );
    default:
      return (
        <ListMeal $disabled={disabled} onClick={onClick}>
          {children}
        </ListMeal>
      );
  }
};

const ImageContainer = styled.div<{ type: "LIST" | "GRID" }>`
  width: ${(props) => (props.type === "GRID" ? "100%" : "140px")};
  aspect-ratio: 1 / 1;
  min-width: ${(props) => (props.type === "GRID" ? null : "140px")};
  min-height: ${(props) => (props.type === "GRID" ? null : "140px")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    display: block;
    padding-top: 100%; /* Ensures a square aspect ratio */
  }
`;

const StyledImage = styled.img<{ type: "LIST" | "GRID" }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Takes 100% width */
  object-fit: cover; /* Ensures the image covers the entire area */
  border-radius: 4px;
`;

function truncateText(text: string, maxLength: number) {
  if (text?.length > maxLength) {
    return text?.slice(0, maxLength) + "...";
  }
  return text;
}

export const SingleMeal: React.FC<{
  meal: any;
  onClick?: any;
  type: "PRODUCT" | "SERVICE";
}> = ({ meal, onClick, type }) => {
  const { selectedBranch } = useAppContext();
  const viewType = selectedBranch?.viewType ?? "LIST";

  const price =
    meal.price ??
    meal.productBaseVariants?.reduce(
      (min: any, item: any) => (item.price < min.price ? item : min),
      meal.productBaseVariants?.[0]
    )?.price;

  const disabled =
    type === "PRODUCT"
      ? !isCurrentTimeInSchedule(meal?.availabilitySchedules ?? {}) ||
        meal?.temporaryHidden
      : false;

  return (
    <Meal
      disabled={disabled}
      viewType={viewType}
      onClick={() => {
        if (onClick && !disabled) onClick(meal);
      }}
    >
      <Flex
        justify="space-between"
        style={{
          marginBottom: viewType === "GRID" ? "16px" : "0px",
        }}
      >
        {viewType === "LIST" && (
          <Flex vertical justify="space-between">
            <div>
              <P
                $size="18"
                $height="24"
                $weight="400"
                style={{
                  marginBottom: "8px",
                  color: disabled ? "#00000065" : "black",
                }}
              >
                {truncateText(ucfirst(meal.name), 200)}
              </P>
              <P
                $size="14"
                $height="20"
                $weight="300"
                $family="Lato-Light"
                style={{
                  color: disabled ? "#00000065" : "black",
                }}
              >
                {truncateText(ucfirst(meal?.description), 300)}
              </P>
            </div>{" "}
            <div>
              <P
                $size="18"
                $height="24"
                $weight="400"
                style={{
                  color: disabled ? "#4f46e565" : "#4f46e5",
                  margin: "0px",
                  whiteSpace: "nowrap",
                }}
              >
                {(price / 100).toFixed(2)} zł
              </P>
            </div>
          </Flex>
        )}
        <ImageContainer type={viewType}>
          {meal.imageUrlS3 && (
            <StyledImage src={meal.imageUrlS3} type={viewType} />
          )}
          <Labels labels={meal?.labels ?? []} />
        </ImageContainer>
      </Flex>
      {viewType === "GRID" && (
        <Flex
          justify="start"
          style={{
            marginBottom: "8px",
          }}
          vertical
          gap={5}
        >
          <P $size="18" $height="24" $weight="400">
            {truncateText(ucfirst(meal.name), 40)}
          </P>
          <P $size="14" $height="20" $weight="300" $family="Lato-Light">
            {truncateText(ucfirst(meal?.description), 300)}
          </P>
        </Flex>
      )}
      {viewType === "GRID" && (
        <Flex justify={"start"}>
          <Space>
            <P
              $size="18"
              $height="24"
              $weight="400"
              style={{
                color: "#4f46e5",
                margin: "0px",
                whiteSpace: "nowrap",
              }}
            >
              {(price / 100).toFixed(2)} zł
            </P>
          </Space>
        </Flex>
      )}
    </Meal>
  );
};
