import React, { useState, useEffect } from "react";
import { CategoriesSlider } from "../../molecules/categories-slider/CategoriesSlider";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useQuery } from "react-query";

import { motion } from "framer-motion";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { GoToCartButton } from "../../molecules/go-to-cart-action/GoToCartButton";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { getProducts } from "../../../service";
import { SingleCategory } from "../../molecules/single-catagory/SingleCategory";
import { scrollToPosition } from "../../../utils";

export const MenuPage: React.FC = () => {
  useScrollToTop();
  const [preventAnimation, setPreventAnimation] = useState(false);
  const [preventScroll, setPreventScroll] = useState(false);

  const { scrollTop } = useScrollFromTop();
  const { cart, selectedBranch, isOpen, scroll, setScroll } = useAppContext();

  const { data: categories = [], isFetching } = useQuery(
    ["/products", selectedBranch],
    () => getProducts(selectedBranch?.id),
    {
      enabled: !!selectedBranch,
    }
  );
  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };
  
  useEffect(() => {
    if (categories?.length > 0) {
      scroll && scrollToPosition(scroll);
      setScroll(0);
    }
  }, [categories]);

  if (!isFetching && categories) {
    return (
      <>
        <CategoriesSlider
          setPreventAnimation={setPreventAnimation}
          setPreventScroll={setPreventScroll}
        />
        <AnimadetPage>
          <div
            style={{
              paddingBottom: cart.length > 0 ? "100px" : "0px",
            }}
          >
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                paddingTop:
                  scrollTop > 64 && !preventAnimation ? "40px" : "0px",
              }}
            >
              {categories
                .filter((e: any) => e.isVisible)
                .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
                .map((category: any) => {
                  if (category.productBases?.length > 0)
                    return (
                      <SingleCategory
                        category={category}
                        preventScroll={preventScroll}
                      />
                    );
                })}
            </motion.div>
          </div>
        </AnimadetPage>
        {(cart.length > 0 || !isOpen) && <GoToCartButton />}
      </>
    );
  }

  return <LoadingScreen />;
};
