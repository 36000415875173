import { Flex } from "antd";
import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { SingleMeal } from "../single-meal/SingleMeal";
import { useNavigate } from "react-router-dom";

const ListMealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const GridMealsWrapper = styled(Flex)``;

const MealsWrapper = ({
  viewType,
  children,
}: {
  viewType: "LIST" | "GRID";
  children: React.ReactNode;
}) => {
  switch (viewType) {
    case "LIST":
      return <ListMealsWrapper>{children}</ListMealsWrapper>;
    case "GRID":
      return (
        <GridMealsWrapper wrap="wrap" gap={8}>
          {children}
        </GridMealsWrapper>
      );
    default:
      return <ListMealsWrapper>{children}</ListMealsWrapper>;
  }
};

export const SingleCategory: React.FC<{
  category: any;
  preventScroll: any;
}> = ({ category, preventScroll }) => {
  const navigate = useNavigate();

  const { selectedBranch, selectedCategory, setSelectedCategory, setScroll } =
    useAppContext();
  const viewType = selectedBranch?.viewType ?? "LIST";

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        // Obliczenie widocznej części elementu
        const visibleHeight =
          Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        if (
          (rect.top > 0 && rect.top < 200) ||
          visibleHeight === windowHeight
        ) {
          setSelectedCategory(category);
        }
      }
    };
    if (!preventScroll) window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [preventScroll]);

  return (
    <div ref={elementRef}>
      <P
        $size="18"
        $height="24"
        $weight="600"
        style={{
          paddingLeft: "12px",
          paddingTop: "24px",
          paddingBottom: category?.descripotion ? "5px" : "15px",
        }}
        id={category?.id}
      >
        {category?.name}
      </P>
      {category?.descripotion && (
        <P
          $size="14"
          $weight="300"
          $height="20"
          $family="Lato-Light"
          style={{
            paddingLeft: "12px",
            paddingTop: "5px",
            paddingBottom: "26px",
          }}
          id={selectedCategory?.id}
        >
          {selectedCategory?.descripotion}
        </P>
      )}

      <MealsWrapper viewType={viewType}>
        {category?.productBases
          ?.filter((e: any) => e.isVisible)
          ?.sort((a: any, b: any) => a.menuPosition - b.menuPosition)
          ?.map((meal: any) => {
            return (
              <SingleMeal
                type={"PRODUCT"}
                key={meal.id}
                meal={meal}
                onClick={() => {
                  if (meal?.productBaseVariants?.length >= 1) {
                    navigate(`/details/${meal.id}`, {
                      state: { from: "home", meal: meal },
                    });
                  }
                  setScroll(window.scrollY);
                  // navigate(`/details/${meal.id}`, {
                  //   state: { from: "home" },
                  // });
                }}
              />
            );
          })}
      </MealsWrapper>
    </div>
  );
};
