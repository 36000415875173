import React, { useEffect, useState } from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Labels } from "../../molecules/labels/Labels";
import { SupportedControls } from "../../molecules/supported-controls/SupportedControls";
import { Form, Select } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getProductDetails } from "../../../service";
import { useLocation, useParams } from "react-router-dom";
import { AdditionsHeader } from "../../atoms/additions-header/AdditionsHeader";
import { AddMealAction } from "../../molecules/add-meal-action/AddMealAction";
import {
  createFormInitialValue,
  createShema,
  joiResolver,
} from "../../../utils";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { ucfirst } from "../../../utils";

const Image = styled.div<{ $imageUrlS3: string }>`
  display: flex;
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 8px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: url(${({ $imageUrlS3 }) => $imageUrlS3});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin-bottom: 12px;
`;

const DetailsWrapper = styled.div`
  padding-bottom: 50px;
  background: white;
  min-height: calc(100vh - 153px);
`;

export const ProductDetails: React.FC = () => {
  useScrollToTop();
  const location = useLocation();
  const { productBaseVariants } = location.state?.meal;
  const [variant, setVariant] = useState(productBaseVariants[0]?.id ?? null);
  const { id } = useParams();
  const { setMealName } = useAppContext();
  const [finalPrice, setFinalPrice] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [controls, setControls] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [extentions, setExtentions] = useState([]);
  const [imageUrlS3, setImageUrlS3] = useState("");

  const { isFetching } = useQuery(
    ["/product-details", id, variant],
    () => getProductDetails({ id, variant: variant }),
    {
      enabled: !!id,
      onSuccess(data) {
        const options = data.options ?? [];
        // const additionals1 = data.additionals;
        // const additionals =
        //   data.additionals.map((addition: any) => {
        //     return addition.controls.map((control: any) => {
        //       return {
        //         ...control,
        //         isAddition: true,
        //         category_name: addition.name,
        //       };
        //     });
        //   }) ?? [];
        // const controls = [...options, ...additionals.flat()];
        setMealName(ucfirst(data.name));
        setLabels(data?.labels ?? []);
        setImageUrlS3(data?.imageUrlS3 ?? "");
        setOptions(options);
        setDescription(ucfirst(data?.description));

        const formattedProduct = {
          ...data,
          productBaseVariants: data?.productBaseVariants.map((variant: any) => {
            const grouped = variant.productExtensionVariant.reduce(
              (acc: any, variant: any) => {
                const categoryId = variant.productExtension.category.id;
                if (!acc[categoryId]) {
                  acc[categoryId] = {
                    category: variant.productExtension.category,
                    variants: [],
                  };
                }
                acc[categoryId].variants.push({
                  id: variant.id,
                  name: variant.productExtension.name,
                  priceExtension: variant.priceExtension,
                  productExtension: variant.productExtension,
                  menuPosition: variant.productExtension.menuPosition,
                });
                return acc;
              },
              {} as Record<number, { category: any; variants: any[] }>
            );

            return {
              ...variant,
              groupedProductExtensionVariants: Object.values(grouped),
            };
          }),
        };

        const groupedVariants =
          formattedProduct.productBaseVariants[0]
            .groupedProductExtensionVariants ?? [];

        setExtentions(groupedVariants ?? []);
        const additionals =
          groupedVariants?.map((group: any) => {
            if (group.category.type === "QUANTITIES") {
              return group.variants.map((variant: any) => {
                return {
                  field_type: "addition",
                  uuid: `addition-${variant.id}`,
                  field_name: variant.productExtension.name,
                  price: variant.priceExtension ?? 0,
                  value: variant.productExtension.minimumRequiredQuantity,
                  getFromStorage: false,
                  isAddition: true,
                  category_name: group.category.name,
                };
              });
            } else {
              return [
                {
                  field_type: "additionSelect",
                  uuid: `additionSelect-${group.category.id}`,
                  minValue: group.category.minimumRequiredElements,
                  field_name: group.category.name,
                  getFromStorage: false,
                  isAddition: true,
                },
              ];
            }
          }) ?? [];
        const controls = [...options, ...additionals.flat()];
        setControls(controls);
        setFinalPrice([
          {
            id: "BASE_PRICE",
            value: data?.productBaseVariants[0].price,
          },
        ]);
      },
    }
  );

  const schema = createShema(controls);
  const methods = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (controls.length > 0) {
      methods.reset(createFormInitialValue(controls));
    }
    return;
  }, [controls]);

  useEffect(() => {
    return () => {
      setMealName("");
    };
  }, []);

  if (!isFetching) {
    return (
      <>
        <FormProvider {...methods}>
          <AnimadetPage>
            <DetailsWrapper>
              <div
                style={{
                  background: "white",
                  borderRadius:
                    "var(--space-space-4, 16px) var(--space-space-4, 16px)  0 0",

                  paddingRight: "8px",
                  paddingLeft: "8px",
                  paddingTop: imageUrlS3 ? "" : "10px",
                }}
              >
                {imageUrlS3 && <Image $imageUrlS3={imageUrlS3} />}
                <Labels showLabel labels={labels} />
                <P
                  $height="20"
                  $size="14"
                  $weight="300"
                  $family="Lato-Light"
                  style={{
                    marginBottom: "24px",
                    marginTop: "12px",
                  }}
                >
                  {description}
                </P>
              </div>
              <div
                style={
                  {
                    // marginTop: "30px",
                  }
                }
              >
                <Form
                  layout="vertical"
                  style={
                    {
                      // borderBottom: "20px solid #f3f4f6",
                    }
                  }
                >
                  {productBaseVariants?.length > 1 && (
                    <Form.Item
                      style={{
                        padding: 8,
                      }}
                    >
                      <Select
                        value={variant}
                        onChange={setVariant}
                        options={productBaseVariants?.map((e: any) => {
                          return {
                            value: e.id,
                            label: `${e.name} (${(e.price / 100).toFixed(
                              2
                            )} zł)`,
                          };
                        })}
                      />
                    </Form.Item>
                  )}

                  {extentions &&
                    extentions.map((extentionCategory: any) => {
                      return (
                        <>
                          <AdditionsHeader
                            key={extentionCategory.category.id}
                            text={extentionCategory.category.name}
                            category={extentionCategory.category}
                          />
                          <div
                            style={{
                              paddingLeft: "12px",
                              paddingRight: "12px",
                              paddingTop: "12px",
                              paddingBottom: "1px",
                              background: "white",
                            }}
                          >
                            {extentionCategory.category.type ===
                            "QUANTITIES" ? (
                              extentionCategory.variants
                                .sort(
                                  (a: any, b: any) =>
                                    a.menuPosition - b.menuPosition
                                )
                                .map((control: any, index: number) => {
                                  return (
                                    <SupportedControls
                                      control={{
                                        field_type: "addition",
                                        uuid: `addition-${control.id}`,
                                        field_name: control.name,
                                        price: control.priceExtension,
                                        getFromStorage: false,
                                        minimumRequiredQuantity:
                                          control.productExtension
                                            .minimumRequiredQuantity,
                                        maximumRequiredQuantity:
                                          control.productExtension
                                            .maximumRequiredQuantity,
                                      }}
                                      key={control.id}
                                      finalPrice={finalPrice}
                                      setFinalPrice={setFinalPrice}
                                    />
                                  );
                                })
                            ) : (
                              <SupportedControls
                                control={{
                                  field_type: "additionSelect",
                                  uuid: `additionSelect-${extentionCategory.category.id}`,
                                  field_name: extentionCategory.category.name,
                                  minimumRequiredElements:
                                    extentionCategory.category
                                      .minimumRequiredElements,
                                  maximumRequiredElements:
                                    extentionCategory.category
                                      .maximumRequiredElements,
                                  extensions: extentionCategory.variants,
                                  type: extentionCategory.category.type,
                                }}
                                finalPrice={finalPrice}
                                setFinalPrice={setFinalPrice}
                              />
                            )}
                          </div>
                        </>
                      );
                    })}
                </Form>
              </div>
            </DetailsWrapper>
          </AnimadetPage>
          <AddMealAction
            finalPrice={finalPrice}
            controls={controls}
            variant={variant}
          />
        </FormProvider>
      </>
    );
  }

  return <LoadingScreen />;
};
